import type {ReactNode} from 'react';

import createTranslationsMap from '@core/translations/createTranslationsMap';
import PaymentBannerPlacement from '@core/payment/banners/paymentBanner/constants/PaymentBannerPlacement';

import HolidayName from '../../../widgets/motivation/constants/HolidayName';

type TranslationsMap = Record<PaymentBannerPlacement, ReactNode>;
type HolidayTranslationsMap = Record<string, TranslationsMap>;

/**
 * Returns custom translations for specific holidays.
 * @returns A map of holiday names to their corresponding translation maps.
 */
export const getCustomTranslationByHolidayMap = (): HolidayTranslationsMap => ({
  [HolidayName.QUARANTINE]: createTranslationsMap((t) => ({
    [PaymentBannerPlacement.NOTIF]: t(
      'paymentBanner',
      'text.notifPaymentBannerDescription.quarantine',
    ),
    [PaymentBannerPlacement.USER]: t(
      'paymentBanner',
      'text.userPaymentBannerDescription.quarantine',
    ),
    [PaymentBannerPlacement.USER_MEDIA]: t(
      'paymentBanner',
      'text.userPaymentBannerDescription.quarantine',
    ),
    [PaymentBannerPlacement.SEARCH]: t(
      'paymentBanner',
      'text.searchPaymentBannerDescription.quarantine',
    ),
    [PaymentBannerPlacement.MENU]: t(
      'paymentBanner',
      'text.menuPaymentBannerDescription.quarantine',
    ),
  })),
  [HolidayName.GAY_PRIDE]: createTranslationsMap((t) => ({
    [PaymentBannerPlacement.NOTIF]: t(
      'paymentBanner',
      'text.notifPaymentBannerDescription.gaypride',
    ),
    [PaymentBannerPlacement.USER]: t(
      'paymentBanner',
      'text.userPaymentBannerDescription.gaypride',
    ),
    [PaymentBannerPlacement.USER_MEDIA]: t(
      'paymentBanner',
      'text.userPaymentBannerDescription.gaypride',
    ),
    [PaymentBannerPlacement.SEARCH]: t(
      'paymentBanner',
      'text.searchPaymentBannerDescription.gaypride',
    ),
    [PaymentBannerPlacement.MENU]: t(
      'paymentBanner',
      'text.menuPaymentBannerDescription.gaypride',
    ),
  })),
  [HolidayName.CHUSEOK]: createTranslationsMap((t) => ({
    [PaymentBannerPlacement.NOTIF]: t(
      'paymentBanner',
      'text.notifPaymentBannerDescription.chuseok',
    ),
    [PaymentBannerPlacement.USER]: t(
      'paymentBanner',
      'text.userPaymentBannerDescription.chuseok',
    ),
    [PaymentBannerPlacement.USER_MEDIA]: t(
      'paymentBanner',
      'text.userPaymentBannerDescription.chuseok',
    ),
    [PaymentBannerPlacement.SEARCH]: t(
      'paymentBanner',
      'text.searchPaymentBannerDescription.chuseok',
    ),
    [PaymentBannerPlacement.MENU]: t(
      'paymentBanner',
      'text.menuPaymentBannerDescription.chuseok',
    ),
  })),
  [HolidayName.BLACK_FRIDAY_ASIA]: createTranslationsMap((t) => ({
    [PaymentBannerPlacement.NOTIF]: t(
      'paymentBanner',
      'text.notifPaymentBannerDescription.blackfridayasia',
    ),
    [PaymentBannerPlacement.USER]: t(
      'paymentBanner',
      'text.userPaymentBannerDescription.blackfridayasia',
    ),
    [PaymentBannerPlacement.USER_MEDIA]: t(
      'paymentBanner',
      'text.userPaymentBannerDescription.blackfridayasia',
    ),
    [PaymentBannerPlacement.SEARCH]: t(
      'paymentBanner',
      'text.searchPaymentBannerDescription.blackfridayasia',
    ),
    [PaymentBannerPlacement.MENU]: t(
      'paymentBanner',
      'text.menuPaymentBannerDescription.blackfridayasia',
    ),
  })),
  [HolidayName.BLACK_FRIDAY]: createTranslationsMap((t) => ({
    [PaymentBannerPlacement.NOTIF]: t(
      'paymentBanner',
      'text.notifPaymentBannerDescription.blackfriday',
    ),
    [PaymentBannerPlacement.USER]: t(
      'paymentBanner',
      'text.userPaymentBannerDescription.blackfriday',
    ),
    [PaymentBannerPlacement.USER_MEDIA]: t(
      'paymentBanner',
      'text.userPaymentBannerDescription.blackfriday',
    ),
    [PaymentBannerPlacement.SEARCH]: t(
      'paymentBanner',
      'text.searchPaymentBannerDescription.blackfriday',
    ),
    [PaymentBannerPlacement.MENU]: t(
      'paymentBanner',
      'text.menuPaymentBannerDescription.blackfriday',
    ),
  })),
});

/**
 * Returns the default translation map for a given holiday.
 * @param holidayName - The name of the holiday.
 * @returns The translation map for the given holiday.
 */
export const getDefaultTranslationMap = (
  holidayName: ReactNode,
): ReturnType<typeof createTranslationsMap> =>
  createTranslationsMap((t) => ({
    [PaymentBannerPlacement.NOTIF]: t(
      'paymentBanner',
      'text.notifPaymentBannerDescription',
    ),
    [PaymentBannerPlacement.USER]: t(
      'paymentBanner',
      'text.userPaymentBannerDescription',
      {
        '{holiday}': holidayName,
      },
    ),
    [PaymentBannerPlacement.USER_MEDIA]: t(
      'paymentBanner',
      'text.userPaymentBannerDescription',
      {
        '{holiday}': holidayName,
      },
    ),
    [PaymentBannerPlacement.SEARCH]: t(
      'paymentBanner',
      'text.searchPaymentBannerDescription',
    ),
    [PaymentBannerPlacement.MENU]: t(
      'paymentBanner',
      'text.menuPaymentBannerDescription',
      {
        '{holiday}': holidayName,
      },
    ),
  }));
