import logger from '@core/logger';
import t from '@core/translations/translate';

import {getHolidayTranslate} from '../../../banners/paymentBanner/utils/getHolidayBannerParams';
import HOLIDAY_TYPE_OCCURRENCE from '../constants/holidayTypeOccurence';
import HolidayName from '../constants/HolidayName';

/**
 * @param {String} name
 * @param {String|Undefined} typeOccurrence
 * @return {String}
 */
export const getHolidayMotivationTitle = (name, typeOccurrence) => {
  if (typeOccurrence) {
    return t(
      'paymentMotivationHolidayDynamic',
      'title.holidayHurryUp.typeOccurence',
    );
  }

  const translation = {
    [HolidayName.BLACK_FRIDAY]: () =>
      t('paymentMotivationHolidayDynamic', 'title.holidayHurryUp.blackfriday'),
    [HolidayName.BLACK_FRIDAY_ASIA]: () =>
      t(
        'paymentMotivationHolidayDynamic',
        'title.holidayHurryUp.blackfridayasia',
      ),
    [HolidayName.CHUSEOK]: () =>
      t('paymentMotivationHolidayDynamic', 'title.holidayHurryUp.chuseok'),
    [HolidayName.GAY_PRIDE]: () =>
      t('paymentMotivationHolidayDynamic', 'title.holidayHurryUp.gaypride'),
    [HolidayName.QUARANTINE]: () =>
      t('paymentMotivationHolidayDynamic', 'title.holidayHurryUp.quarantine'),
  }[name];

  if (translation) {
    return translation();
  }

  logger.sendWarning(
    `[getHolidayMotivationTitle] LOST TRANSLATE: (feature: paymentMotivationHolidayDynamic, key: ${name})`,
  );

  return name;
};

/**
 * @param {String} name
 * @param {String} typeOccurrence
 * @return {String}
 */
export const getHolidayMotivationText = (name, typeOccurrence) => {
  if (typeOccurrence) {
    return t(
      'paymentMotivationHolidayDynamic',
      'text.holidayOffer.typeOccurence',
      {'{holiday}': getHolidayTranslate(name)},
    );
  }

  const translation = {
    [HolidayName.BLACK_FRIDAY]: () =>
      t('paymentMotivationHolidayDynamic', 'text.holidayOffer.blackfriday'),
    [HolidayName.BLACK_FRIDAY_ASIA]: () =>
      t(
        'paymentMotivationHolidayDynamic',
        'text.holidayHurryUp.blackfridayasia',
      ),
    [HolidayName.CHUSEOK]: () =>
      t('paymentMotivationHolidayDynamic', 'text.holidayOffer.chuseok'),
    [HolidayName.GAY_PRIDE]: () =>
      t('paymentMotivationHolidayDynamic', 'text.holidayOffer.gaypride'),
    [HolidayName.QUARANTINE]: () =>
      t('paymentMotivationHolidayDynamic', 'text.holidayOffer.quarantine'),
  }[name];

  if (translation) {
    return translation();
  }

  logger.sendWarning(
    `[getHolidayMotivationText] LOST TRANSLATE: (feature: paymentMotivationHolidayDynamic, key: ${name})`,
  );

  return name;
};

/**
 * @param {String} name
 * @param {String} typeOccurrence
 * @return {String}
 */
export const getHolidayMotivationHeader = (name, typeOccurrence) => {
  if (typeOccurrence) {
    const translation = {
      new: () =>
        t(
          'paymentMotivationHolidayDynamic',
          'text.banner.header.typeOccurence.new',
        ),
      medium: () =>
        t(
          'paymentMotivationHolidayDynamic',
          'text.banner.header.typeOccurence.medium',
          {'{holiday}': getHolidayTranslate(name)},
        ),
      old: () =>
        t(
          'paymentMotivationHolidayDynamic',
          'text.banner.header.typeOccurence.old',
          {'{holiday}': getHolidayTranslate(name)},
        ),
    }[typeOccurrence];

    if (translation) {
      return translation();
    }

    logger.sendWarning(
      `[getHolidayMotivationHeader] LOST TRANSLATE: (feature: paymentMotivationHolidayDynamic, key: ${typeOccurrence})`,
    );

    return typeOccurrence;
  }

  const translation = {
    [HolidayName.BLACK_FRIDAY]: () =>
      t('paymentMotivationHolidayDynamic', 'text.banner.header.blackfriday'),
    [HolidayName.BLACK_FRIDAY_ASIA]: () =>
      t(
        'paymentMotivationHolidayDynamic',
        'text.banner.header.blackfridayasia',
      ),
    [HolidayName.CHUSEOK]: () =>
      t('paymentMotivationHolidayDynamic', 'text.banner.header.chuseok'),
    [HolidayName.GAY_PRIDE]: () =>
      t('paymentMotivationHolidayDynamic', 'text.banner.header.gaypride'),
    [HolidayName.QUARANTINE]: () =>
      t('paymentMotivationHolidayDynamic', 'text.banner.header.quarantine'),
  }[name];

  if (translation) {
    return translation();
  }

  logger.sendWarning(
    `[getHolidayMotivationHeader] LOST TRANSLATE: (feature: paymentMotivationHolidayDynamic, key: ${name})`,
  );

  return name;
};

/**
 * @param {String} name
 * @param {String} typeOccurrence
 * @return {String}
 */
export const getHolidayMotivationSubTitle = (name, typeOccurrence) => {
  const translation = {
    [HOLIDAY_TYPE_OCCURRENCE.NEW]: () =>
      t(
        'paymentMotivationHolidayDynamic',
        'text.banner.subtitle.typeOccurence.new',
        {'{holiday}': getHolidayTranslate(name)},
      ),
    [HOLIDAY_TYPE_OCCURRENCE.MEDIUM]: () =>
      t(
        'paymentMotivationHolidayDynamic',
        'text.banner.subtitle.typeOccurence.medium',
      ),
    [HOLIDAY_TYPE_OCCURRENCE.OLD]: () =>
      t(
        'paymentMotivationHolidayDynamic',
        'text.banner.subtitle.typeOccurence.old',
      ),
  }[typeOccurrence];

  if (translation) {
    return translation();
  }

  logger.sendWarning(
    `[getHolidayMotivationSubTitle] LOST TRANSLATE: (feature: paymentMotivationHolidayDynamic, key: ${typeOccurrence})`,
  );

  return typeOccurrence;
};
