import {useQuery} from '@apollo/client';
import includes from 'lodash/includes';

import useActivePackage from '@core/payment/widgets/package/utils/useActivePackage';

import HOLIDAY_BACKGROUND_QUERY from '@phoenix/user/holiday/graphql/queries/holidayBackground.gql';

const usePaymentHolidayMotivation = () => {
  const {data, error, loading} = useQuery(HOLIDAY_BACKGROUND_QUERY);
  const {activePackage} = useActivePackage();

  if (loading || error || !activePackage || !data) {
    return {
      loading,
      error,
      name: null,
      color: null,
      layout: null,
      typeOccurrence: null,
      available: false,
    };
  }

  const {name, color, layout, typeOccurrence, processors} =
    data.userFeatures.holiday;

  return {
    loading,
    error,
    name,
    color,
    layout,
    typeOccurrence,
    available: Boolean(name) && includes(processors, activePackage.method),
  };
};

export default usePaymentHolidayMotivation;
