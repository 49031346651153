import type {FC} from 'react';
import React from 'react';
import cn from 'classnames';

import {
  PlaceholderBar,
  PlaceholderCircle,
  Card,
  Row,
  Group,
  SpacingShort,
} from '@phoenix/ui';
import {SpacingSize, Align} from '@phoenix/ui/constants';

import css from '../styles/PaymentCoinsPackage.css';

const PaymentCoinsPackagesPlaceholder: FC = () => (
  <Group space={SpacingSize.SHORT}>
    {[1, 2, 3, 4].map((i) => (
      <Card key={`package${i}`} className={cn(css.package)}>
        <SpacingShort onlyRight>
          <Row horizontal align={Align.BETWEEN}>
            <div className={css.icon}>
              <PlaceholderCircle size={40} />
            </div>
            <PlaceholderBar size={8} />
          </Row>
        </SpacingShort>
      </Card>
    ))}
  </Group>
);

export default PaymentCoinsPackagesPlaceholder;
