import React from 'react';

import {SpacingDirection, SpacingSize, Align} from '@phoenix/ui/constants';
import {PlaceholderBar, PlaceholderInput, Spacing, Row} from '@phoenix/ui';
import PopupFrame from '@phoenix/popup/components/PopupFrame';
import PaymentCoinsPackagePlaceholder from '@phoenix/payment/widgets/package/components/PaymentCoinsPackagesPlaceholder';

const BuyMessagesPopupPlaceholder = () => (
  <PopupFrame
    data-test="placeholder"
    content={
      <Spacing direction={SpacingDirection.VERTICAL} withoutBottom>
        <Row horizontal={false} align={Align.CENTER}>
          <PlaceholderBar inline size={8} />
        </Row>
        <Row space={SpacingSize.SHORT}>
          <PaymentCoinsPackagePlaceholder />
        </Row>
        <Row align={Align.CENTER}>
          <PlaceholderBar inline size={8} />
          <PlaceholderBar inline size={4} />
        </Row>
        <Row align={Align.CENTER}>
          <PlaceholderBar inline size={8} />
          <PlaceholderBar inline size={7} />
        </Row>
      </Spacing>
    }
    actions={<PlaceholderInput size={10} />}
  />
);

export default BuyMessagesPopupPlaceholder;
