import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';

import {ViaEnum} from '@core/types/graphql';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import useActivePackage from '@core/payment/widgets/package/utils/useActivePackage';
import usePaymentPackages from '@core/payment/widgets/package/utils/usePaymentPackages';
import PAYMENT_SOURCES from '@core/payment/common/constants/paymentSources';
import useOneClick from '@core/payment/forms/card/utils/useOneClick';
import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import withStaticPaymentParamsProvider from '@core/payment/common/utils/withStaticPaymentParamsProvider';
import getProcessPaymentFlow from '@core/payment/payProcess/utils/getProcessPaymentFlow';
import {setPageEnterTime} from '@core/payment/payProcess/utils/pageViewTime';
import useWalletAllowedMethods from '@core/payment/common/utils/useWalletAllowedMethods';
import {APPLE_PAY_TEST_ENVIRONMENT} from '@core/payment/common/constants/walletVisualTestCookies';
import {getCookie} from '@core/utils/cookie';
import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import {WALLET_METHODS} from '@core/payment/common/constants/paymentMethods';
import PAYMENT_ONECLICK_FLOWS from '@core/payment/common/constants/paymentOneclickFlows';

import PaymentDetails from '@phoenix/payment/widgets/details/components/PaymentDetails';
import {Align, SpacingSize} from '@phoenix/ui/constants';
import {Actions, ButtonPay, Group, Spacing} from '@phoenix/ui';
import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';
import PopupFrame from '@phoenix/popup/components/PopupFrame';
import getBuyMessagesPopupFlow from '@phoenix/payment/payProcess/paymentFlow/flows/getBuyMessagesPopupFlow';
import PaymentCardOneClickInfo from '@phoenix/payment/forms/card/components/PaymentCardOneClickInfo';
import PaymentBillingPolicy from '@phoenix/payment/widgets/billingPolicy/containers/PaymentBillingPolicy';
import usePaymentButtonTitle from '@phoenix/payment/widgets/buttons/utils/usePaymentButtonTitle';
import useTrackPaymentVisit from '@phoenix/payment/pages/utils/useTrackPaymentVisit';
import PaymentPackagesAccordion from '@phoenix/payment/widgets/package/containers/PaymentPackagesAccordion';

import BuyMessagesPopupPlaceholder from '../components/BuyMessagesPopupPlaceholder';
import BuyMessagesPopupMotivation from '../components/BuyMessagesPopupMotivation';
import BuyMessagePopupApplePayButton from './BuyMessagePopupApplePayButton';

/**
 * Get message packages and messages popup, if they exist
 * show error instead
 */
const BuyMessagesPopup = ({via, urlParams}) => {
  setPageEnterTime();
  useTrackPaymentVisit();
  const isPhone = usePhoneBreakpoint();

  const {methods, loading: walletMethodsLoading} = useWalletAllowedMethods();
  const buttonTitle = usePaymentButtonTitle({walletMethods: methods});
  const {error, loading, packages, defaultActiveTab} = usePaymentPackages({
    needResetCache: true,
  });
  const {activePackage} = useActivePackage();
  const {
    loading: oneClickLoading,
    oneClickCardNumber,
    oneClickFlow,
  } = useOneClick();
  const history = useHistory();

  const allowedApplePay =
    methods.includes(WALLET_METHODS.APPLE_PAY) ||
    getCookie(APPLE_PAY_TEST_ENVIRONMENT);

  const [disabled, setDisabled] = useState(false);

  const handlePay = useCallback(() => {
    setDisabled(true);

    if (oneClickFlow !== PAYMENT_ONECLICK_FLOWS.SIMPLE) {
      const paymentUrl = generatePayUrl({
        stage: PAYMENT_ACTIONS.MESSAGE,
        urlParams: {
          ...urlParams,
          via,
          stockId: activePackage.stockId,
        },
      });

      history.push(paymentUrl);
      return;
    }

    const processPaymentFlow = getProcessPaymentFlow(getBuyMessagesPopupFlow());

    processPaymentFlow({
      form: {
        hidePaymentForm: 1,
      },
      activePackage,
      action: PAYMENT_ACTIONS.MESSAGE,
      returnPath: urlParams.returnPath,
      via,
    });
  }, [activePackage, via, urlParams, oneClickFlow, history]);

  const handleClickCardNumber = useCallback(() => {
    history.push(
      generatePayUrl({
        stage: PAYMENT_ACTIONS.MESSAGE,
        urlParams: {
          ...urlParams,
          via: ViaEnum.msngr_1click_changeCard,
          withOpenedCardForm: true,
        },
      }),
    );
  }, [history, urlParams]);

  if (loading || oneClickLoading || !buttonTitle || walletMethodsLoading) {
    return <BuyMessagesPopupPlaceholder />;
  }

  if (error) {
    return <ErrorBoundary error={error} />;
  }

  return (
    <PopupFrame
      content={
        <PopupLoadTracker>
          <Spacing onlyBottom>
            <BuyMessagesPopupMotivation />
            <PaymentPackagesAccordion
              defaultActiveTab={defaultActiveTab}
              packages={packages}
              withTabs={false}
              showBenefitsOnAllPackages
              squareBenefitsPlusIcon
            />
          </Spacing>
          <Group align={Align.CENTER}>
            <PaymentDetails />
            <PaymentCardOneClickInfo
              showChargeInfo={false}
              cardNumber={oneClickCardNumber}
              onClick={handleClickCardNumber}
              oneClickUsed
              spaced={false}
              withoutLeft
              withoutRight
            />
            <PaymentBillingPolicy paymentButtonTitle={buttonTitle} />
          </Group>
        </PopupLoadTracker>
      }
      actions={
        <Actions
          fullWidth
          vertical={isPhone}
          reverse={!isPhone}
          stretch
          align={Align.STRETCH}
          itemSpacing={SpacingSize.SHORT}
        >
          {allowedApplePay && (
            <BuyMessagePopupApplePayButton
              setDisabled={setDisabled}
              disabled={disabled}
            />
          )}
          <ButtonPay
            disabled={disabled}
            adaptive
            fullWidth
            onClick={handlePay}
            trackingName="buyMessages"
            data-test="messagesPopupAction"
          >
            {buttonTitle}
          </ButtonPay>
        </Actions>
      }
    />
  );
};

BuyMessagesPopup.propTypes /* remove-proptypes */ = {
  via: PropTypes.string.isRequired,
  urlParams: PropTypes.shape({
    viaProfileId: PropTypes.string,
    returnPath: PropTypes.string,
  }).isRequired,
};

export default withStaticPaymentParamsProvider(BuyMessagesPopup, ({via}) => ({
  action: PAYMENT_ACTIONS.MESSAGE,
  via,
  source: PAYMENT_SOURCES.POPUP,
}));
