import type {FC} from 'react';
import React, {useEffect} from 'react';
import cn from 'classnames';

import logger from '@core/logger';

import {Icon} from '@phoenix/ui';

import css from '../styles/PaymentUnknownTabIcon.css';

type PaymentUnknownTabIconProps = {
  className?: string;
  tabLogo: string;
};

const PaymentUnknownTabIcon: FC<PaymentUnknownTabIconProps> = ({
  className,
  tabLogo,
}) => {
  useEffect(() => {
    logger.sendError(
      `[PaymentTabIconInProgress] ${tabLogo} is not implemented on frontend`,
    );
  }, [tabLogo]);

  return (
    <div className={cn(css.icon, className)}>
      <Icon type="heart-broken" danger />
    </div>
  );
};

export default PaymentUnknownTabIcon;
