import React from 'react';
import PropTypes from 'prop-types';

import {H2, H4, TextMuted} from '@phoenix/typography';
import {Group, Row} from '@phoenix/ui';
import {SpacingSize} from '@phoenix/ui/constants';

import css from '../styles/PaymentHolidayMotivationText.css';

const PaymentHolidayMotivationText = ({
  title,
  text,
  isBigText = false,
  spacingSize = SpacingSize.SHORT,
}) => {
  const Heading = isBigText ? H2 : H4;

  return (
    <Row space={spacingSize}>
      <Group className={css.wrap} space={SpacingSize.SHORT}>
        <Heading className={css.title}>{title}</Heading>
        <TextMuted small>{text}</TextMuted>
      </Group>
    </Row>
  );
};

PaymentHolidayMotivationText.propTypes /* remove-proptypes */ = {
  isBigText: PropTypes.bool,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  spacingSize: PropTypes.oneOf(Object.values(SpacingSize)),
};

export default PaymentHolidayMotivationText;
