import {getClientInstance} from '@core/graphql/client';
import GLOBAL_FREE_MESSAGES_BUY_MESSAGES_FOR_FREE_USER_QUERY from '@core/messenger/common/graphql/queries/globalFreeMessagesBuyMessagesForFreeUser.gql';
import type {GlobalFreeMessagesBuyMessagesForFreeUserQuery} from '@core/messenger/common/graphql/queries/globalFreeMessagesBuyMessagesForFreeUser';

/**
 * @info If you need use as a hook, you can use 'useGlobalFreeMessagesBuyMessagesForFreeUser' hook
 */
const getGlobalFreeMessagesBuyMessagesForFreeUser =
  async (): Promise<boolean> => {
    const {data} =
      await getClientInstance().query<GlobalFreeMessagesBuyMessagesForFreeUserQuery>(
        {
          query: GLOBAL_FREE_MESSAGES_BUY_MESSAGES_FOR_FREE_USER_QUERY,
        },
      );

    return (
      data?.messenger?.initialData?.globalFreeMessages
        ?.buyMessagesForFreeUser || false
    );
  };

export default getGlobalFreeMessagesBuyMessagesForFreeUser;
