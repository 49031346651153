import {ViaEnum} from '@core/types/graphql';
import PaymentBannerPlacement from '@core/payment/banners/paymentBanner/constants/PaymentBannerPlacement';

const UPGRADE_VIA_BY_PLACEMENT = {
  /**
   * via for holiday banner by placement
   */
  [PaymentBannerPlacement.NOTIF]: ViaEnum.notifications_popup_banner_h, // activity popup (click by "ear")
  [PaymentBannerPlacement.SEARCH]: ViaEnum.search_limit_h, // bottom of search results
  [PaymentBannerPlacement.USER]: ViaEnum.looking_for_h, // in user profile
  [PaymentBannerPlacement.USER_MEDIA]: ViaEnum.looking_for_media, // in user profile on media tab
  [PaymentBannerPlacement.MENU]: ViaEnum.app_index_h, // in profile/menu pages
};

export default UPGRADE_VIA_BY_PLACEMENT;
