import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import getHistory from '@core/application/utils/getHistory';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import isCardOneClickAllowed from '@core/payment/forms/card/utils/isCardOneClickAllowed';
import PAYMENT_SOURCES from '@core/payment/common/constants/paymentSources';

import groupKey from '@phoenix/freeMessagesMotivation/constants/popupGroupKey';

import BuyMessagesPopup from '../containers/BuyMessagesPopup';

/**
 * Open popup for buying package of messages
 * @param {object} props
 * @param {ViaEnum} props.via
 * @param {object} [props.urlParams]
 */
const openBuyMessagesPopupIfNeed = async ({via, urlParams = {}}) => {
  if (
    await isCardOneClickAllowed({
      source: PAYMENT_SOURCES.POPUP,
      fetchPolicy: 'network-only',
      via,
    })
  ) {
    PopupService.openPopup(
      <BuyMessagesPopup via={via} urlParams={urlParams} />,
      {
        groupKey, // to replace the popup opened by openFreeMessagesRewardsPopup with this one
        small: true,
        trackingName: 'buyMessages',
      },
    );

    return;
  }

  getHistory().push(
    generatePayUrl({
      stage: PAYMENT_ACTIONS.MESSAGE,
      urlParams: {
        via,
        ...urlParams,
      },
    }),
  );
};

export default openBuyMessagesPopupIfNeed;
