import React from 'react';
import PropTypes from 'prop-types';

import {
  Group,
  Card,
  SpacingShort,
  PlaceholderInput,
  Row,
  PlaceholderBar,
} from '@phoenix/ui';
import {Align, SpacingSize} from '@phoenix/ui/constants';

import PaymentPackageHeaderPlaceholder from './PaymentPackageHeaderPlaceholder';
import css from '../styles/PaymentPackageAccordion.css';

const PackagePlaceholder = ({isActive = false}) => (
  <Card>
    <div className={css.header}>
      <PaymentPackageHeaderPlaceholder
        checked={isActive}
        spacingSize={SpacingSize.SHORT}
      />
    </div>
    {isActive && (
      <Card onPageBackground shadowLevel={0}>
        <SpacingShort>
          <Group>
            <PlaceholderBar small size={4} />
            <PlaceholderBar small size={4} />
            <PlaceholderBar small size={4} />
            <PlaceholderBar small size={4} />
          </Group>
        </SpacingShort>
      </Card>
    )}
  </Card>
);

PackagePlaceholder.propTypes /* remove-proptypes */ = {
  isActive: PropTypes.bool,
};

const PaymentPackagesAccordionPlaceholder = ({isActive = true}) => (
  <Group space={SpacingSize.SHORT}>
    <Card onPageBackground>
      <Row align={Align.CENTER}>
        <PlaceholderInput size={3} inline />
      </Row>
    </Card>
    <PackagePlaceholder isActive={isActive} />
    <PackagePlaceholder />
    <PackagePlaceholder />
    <PackagePlaceholder />
  </Group>
);

PaymentPackagesAccordionPlaceholder.propTypes /* remove-proptypes */ = {
  isActive: PropTypes.bool,
};

export default PaymentPackagesAccordionPlaceholder;
