import {WALLET_METHODS} from '@core/payment/common/constants/paymentMethods';
import cachePurchasedPackage from '@core/payment/payProcess/paymentFlow/methods/cachePurchasedPackage';
import reportStartPayment from '@core/payment/payProcess/paymentFlow/methods/reportStartPayment';
import reportEndPayment from '@core/payment/payProcess/paymentFlow/methods/reportEndPayment';
import processPaymentAnswer from '@core/payment/payProcess/paymentFlow/methods/processPaymentAnswer';
import addSuccessOrder from '@core/payment/payProcess/paymentFlow/methods/addSuccessOrder';
import trackApplePayClick from '@core/payment/payProcess/paymentFlow/methods/trackApplePayClick';
import resetSelectedAdditionalPackages from '@core/payment/payProcess/paymentFlow/methods/resetSelectedAdditionalPackages';
import updateCSRFToken from '@core/payment/payProcess/paymentFlow/methods/updateCSRFToken';
import addBrowserDetails from '@core/payment/payProcess/paymentFlow/methods/addBrowserDetails';
import type {PaymentProcessStep} from '@core/payment/payProcess/types/paymentProcess';

import getWalletPayByMethod from '@phoenix/payment/payProcess/paymentFlow/methods/getWalletPayByMethod';

import getMessageStepsAfterPayment from '../methods/getMessageStepsAfterPayment';

const getApplePayBuyMessagesPopupFlow = (): PaymentProcessStep[] => {
  return [
    reportStartPayment,
    trackApplePayClick,
    cachePurchasedPackage,
    addBrowserDetails,
    getWalletPayByMethod(WALLET_METHODS.APPLE_PAY),
    updateCSRFToken,
    reportEndPayment,
    processPaymentAnswer,
    resetSelectedAdditionalPackages,
    addSuccessOrder,
    getMessageStepsAfterPayment,
  ];
};

export default getApplePayBuyMessagesPopupFlow;
