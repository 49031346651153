import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import isCompactViewportCached from '@core/responsive/isCompactViewportCached';
import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';

import {Card, Spacing} from '@phoenix/ui';
import {SpacingSize} from '@phoenix/ui/constants';

import PaymentTabIcon from './PaymentTabIcon';
import css from '../styles/PaymentTabContent.css';

/**
 * @desc Content of tab
 */
const PaymentTabContent = ({
  tabLogo,
  active,
  inverse,
  onClick,
  // used for styling payment tabs on web
  highlightOnActive = false,
  darkArrow = false,
  spacingSize = SpacingSize.SHORT,
  tabName,
  withArrow = false,
  withoutSubstrateOnArrow = false,
}) => {
  const isCompact = isCompactViewportCached();

  const content = (
    <Spacing size={spacingSize} className={css.content}>
      {withArrow && (
        <div
          className={cn(
            css.arrowEdge,
            darkArrow && css.darkArrow,
            withoutSubstrateOnArrow && css.withoutSubstrate,
          )}
        />
      )}
      <PaymentTabIcon tabLogo={tabLogo} active={active} inverse={inverse} />
    </Spacing>
  );
  const trackingName = onClick ? `${tabName}MethodTab` : null;

  if (highlightOnActive && active) {
    return (
      <Card
        active={active}
        shadowLevel={0}
        onClick={onClick}
        trackingName={trackingName}
        className={cn(
          css.tab,
          active && css.active,
          isCompact && css.fixedHeightTab,
        )}
        data-test={`${tabName}Tab`}
      >
        {content}
      </Card>
    );
  }

  return (
    <AddBabciaUBTracking trackingName={trackingName}>
      <div
        role="button"
        tabIndex="0"
        className={cn(
          css.tab,
          active && css.active,
          isCompact && css.fixedHeightTab,
        )}
        onClick={onClick}
        data-test={`${tabName}Tab`}
      >
        {content}
      </div>
    </AddBabciaUBTracking>
  );
};

PaymentTabContent.propTypes /* remove-proptypes */ = {
  tabLogo: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  darkArrow: PropTypes.bool,
  inverse: PropTypes.bool,
  onClick: PropTypes.func,
  highlightOnActive: PropTypes.bool,
  spacingSize: PropTypes.string,
  tabName: PropTypes.string.isRequired,
  withArrow: PropTypes.bool,
  withoutSubstrateOnArrow: PropTypes.bool,
};

export default PaymentTabContent;
