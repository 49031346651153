import type {FC} from 'react';
import React from 'react';
import cn from 'classnames';

import t from '@core/translations/translate';
import isCompactViewportCached from '@core/responsive/isCompactViewportCached';
import {PAY_BY_BANK} from '@core/payment/common/constants/paymentTabLogos';
import {Method} from '@core/types';
import {localStorage} from '@core/utils/storage';

import PaymentUnknownTabIcon from './PaymentUnknownTabIcon';
import css from '../styles/PaymentTabIcon.css';

type PaymentTabIconProps = {
  active?: boolean;
  inverse?: boolean;
  tabLogo: string;
};

/**
 * @desc Icon of payment method
 * @see TabContent
 */
const PaymentTabIcon: FC<PaymentTabIconProps> = ({
  active = false,
  inverse,
  tabLogo,
}) => {
  const isCompact = isCompactViewportCached();
  const logoCss = css[tabLogo.toLowerCase()];

  if (
    !logoCss ||
    // @important: need fo testing
    (tabLogo === Method.card && localStorage.getItem('isUnknownMethod'))
  ) {
    return (
      <PaymentUnknownTabIcon
        tabLogo={tabLogo}
        className={cn(css.wrap, active && css.active)}
      />
    );
  }

  const className = cn(logoCss, inverse && css.inverse);

  if (tabLogo === PAY_BY_BANK) {
    return (
      <div
        className={cn(
          isCompact ? css.wrap : css.verticalWrap,
          isCompact && css.horizontalWrap,
          active && css.active,
        )}
      >
        <div className={className} />
        <div className={css.text}>{t('paymentPage', 'text.pay_by_bank')}</div>
      </div>
    );
  }

  return (
    <div className={cn(css.wrap, active && css.active)}>
      <div className={className} />
    </div>
  );
};

export default PaymentTabIcon;
