import {getClientInstance} from '@core/graphql/client';
import type {RecipientWithMessagesFragment} from '@core/messenger/common/graphql/fragments/recipientMessages';
import RECIPIENT_WITH_MESSAGES_FRAGMENT from '@core/messenger/common/graphql/fragments/recipientMessages.gql';
import type {RecipientFragment} from '@core/messenger/common/graphql/fragments/recipient';
import RECIPIENT_FRAGMENT from '@core/messenger/common/graphql/fragments/recipient.gql';

type UpdateFreeMessageMotivationBannerParams = {
  recipientId: string;
  isShowBanner: boolean;
};

type Recipient = RecipientFragment | RecipientWithMessagesFragment;

const updateFreeMessageMotivationBanner = ({
  recipientId,
  isShowBanner,
}: UpdateFreeMessageMotivationBannerParams): void => {
  const client = getClientInstance();

  let fragment = {
    fragment: RECIPIENT_WITH_MESSAGES_FRAGMENT,
    fragmentName: 'RecipientWithMessages',
    id: `Recipient:${recipientId}`,
  };

  let recipient: Recipient =
    client.readFragment<RecipientWithMessagesFragment>(fragment);

  if (!recipient) {
    /** * We have one case {@see startMessengerListeners} when we need to read * 'recipient' even when 'messages' was not requested. */
    fragment = {
      fragment: RECIPIENT_FRAGMENT,
      fragmentName: 'Recipient',
      id: `Recipient:${recipientId}`,
    };
    recipient = client.readFragment<RecipientFragment>(fragment);
  }

  // If recipient d`ont exist, do not writeFragment
  if (recipient) {
    client.writeFragment<Recipient>({
      ...fragment,
      data: {
        ...recipient,
        ...(recipient.freeMessageMotivation && {
          freeMessageMotivation: {isShowBanner},
        }),
      },
      overwrite: true,
    });
  }
};

export default updateFreeMessageMotivationBanner;
