import resetPaymentPackagesCache from '@core/payment/common/utils/resetPaymentPackagesCache';
import PopupService from '@core/popup/utils/PopupService';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import getHistory from '@core/application/utils/getHistory';
import cachedPurchasedPackage from '@core/payment/common/utils/cachedPurchasedPackage';
import PaymentPageSuccessOrders from '@core/payment/payProcess/utils/PaymentPageSuccessOrders';
import NotificationsService from '@core/systemNotifications/utils/NotificationsService';
import type {PaymentProcessStep} from '@core/payment/payProcess/types/paymentProcess';

import openBuyMessagesSuccesPopup from '@phoenix/buyMessagesPopup/utils/openBuyMessagesSuccesPopup';

const getMessageStepsAfterPayment: PaymentProcessStep = async (data) => {
  const {paymentResult, paymentData} = data;

  // Close previous popup
  PaymentPageSuccessOrders.clear();

  if (paymentResult.failPageLogic) {
    // When we got Decline we redirect to PP and reset Cached package
    const purchasedPackage = cachedPurchasedPackage.get();

    // Need to remove previous packages data, because after fail all packages will be change
    resetPaymentPackagesCache(false);

    getHistory().push(
      generatePayUrl({
        stage: PAYMENT_ACTIONS.MESSAGE,
        urlParams: {
          via: paymentResult.failPageLogic,
          returnPath: paymentData.returnPath,
          prevVia: paymentData.prevVia,
          stockId: purchasedPackage.stockId,
        },
      }),
    );
  }

  if (paymentResult.status) {
    // Close BuyMessagesPopup and show success notification
    PopupService.closePopup();
    NotificationsService.addNotification({
      type: NotificationsService.TYPES.SUCCESS_PAYMENT,
      onClick: openBuyMessagesSuccesPopup,
    });
  }

  return data;
};

export default getMessageStepsAfterPayment;
