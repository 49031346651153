import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import BuyMessagesPopupSuccess from '../components/BuyMessagesPopupSuccess';

/**
 * Open success popup after buying messages
 */
const openBuyMessagesSuccesPopup = () => {
  PopupService.openPopup(<BuyMessagesPopupSuccess />, {
    small: true,
    trackingName: 'buyMessagesSuccess',
    autoLoadTracking: true,
  });
};

export default openBuyMessagesSuccesPopup;
