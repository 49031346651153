import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import t from '@core/translations/translate';

import {ButtonPrimary, Icon} from '@phoenix/ui';
import {TextMuted} from '@phoenix/typography';
import {IconSize, SpacingSize} from '@phoenix/ui/constants';
import PopupNotice from '@phoenix/popup/components/PopupNotice';

import css from '../styles/BuyMessagesPopupSuccess.css';

/**
 * Use separate function to prevent close all popups
 */
const closePopup = () => PopupService.closePopup();

/**
 * Popup with message about success purchase of messages, with animation
 */
const BuyMessagesPopupSuccess = () => (
  <div className={css.container}>
    <PopupNotice
      icon={<Icon success type="check" size={IconSize.SIZE_96} />}
      iconSpacing={SpacingSize.SHORT}
      title={t('buyMessagesPopup', 'title.payment_successfull')}
      description={
        <TextMuted>
          {t('buyMessagesPopup', 'text.additional_fees_warning')}
        </TextMuted>
      }
      actions={[
        <ButtonPrimary
          adaptive
          fullWidth
          onClick={closePopup}
          trackingName="closePopup"
        >
          {t('buyMessagesPopup', 'button.back_to_the_site')}
        </ButtonPrimary>,
      ]}
    />
  </div>
);

export default BuyMessagesPopupSuccess;
