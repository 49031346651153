import HolidayName from '../../../widgets/motivation/constants/HolidayName';

/**
 * A list of custom holidays that are completely different from others, for example, has different texts
 * @see getHolidayBannerParams.js
 * @type {Array}
 */
const CUSTOM_HOLIDAY = [
  HolidayName.BLACK_FRIDAY,
  HolidayName.BLACK_FRIDAY_ASIA,
  HolidayName.CHUSEOK,
  HolidayName.GAY_PRIDE,
  HolidayName.QUARANTINE,
];

export default CUSTOM_HOLIDAY;
