import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {PAY_BY_BANK} from '@core/payment/common/constants/paymentTabLogos';
import PROCESSING_STATUS from '@core/payment/common/constants/processingStatus';
import usePaymentProcessingStatus from '@core/payment/common/utils/usePaymentProcessingStatus';
import {paymentTabPropType} from '@core/payment/widgets/package/constants/propTypes';
import ColorScheme from '@core/theming/constants/ColorScheme';

import {Card} from '@phoenix/ui';
import {SpacingSize} from '@phoenix/ui/constants';
import useThemeColorScheme from '@phoenix/theming/utils/useThemeColorScheme';

import PaymentTabContent from './PaymentTabContent';
import css from '../styles/PaymentTabsSwitcher.css';

/**
 * @desc Switcher for payment tabs
 * @info Mob realization
 */
const PaymentTabsSwitcher = ({tabs, activeTab, setActiveTab}) => {
  const colorScheme = useThemeColorScheme();
  const processingStatus = usePaymentProcessingStatus();

  const disabled = processingStatus === PROCESSING_STATUS.PROCESSING;

  return (
    <Card className={cn(css.wrap, disabled && css.disabled)} onPageBackground>
      {tabs.map((tab) => {
        return (
          <div key={tab.tabName} className={css.tab}>
            <PaymentTabContent
              inverse={colorScheme === ColorScheme.DARK}
              active={tab.tabName === activeTab.tabName}
              tabLogo={tab.tabLogo}
              // eslint-disable-next-line local-rules/tracking-name -- has tracking inside
              onClick={() => !disabled && setActiveTab(tab)}
              highlightOnActive
              spacingSize={
                tab.tabLogo === PAY_BY_BANK
                  ? SpacingSize.SHORT
                  : SpacingSize.SHORTEST
              }
              tabName={tab.tabName}
            />
          </div>
        );
      })}
    </Card>
  );
};

PaymentTabsSwitcher.propTypes /* remove-proptypes */ = {
  tabs: PropTypes.arrayOf(paymentTabPropType).isRequired,
  activeTab: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    paymentTabPropType,
  ]),
  setActiveTab: PropTypes.func.isRequired,
};

export default PaymentTabsSwitcher;
