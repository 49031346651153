import type {ReactNode} from 'react';

import t from '@core/translations/translate';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import usePaymentParams from '@core/payment/common/utils/usePaymentParams';

const uniqueFeaturesCount = {
  'Extra security': 4,
  'Extra Benefits': 4,
  Chataholic: 2,
};

/**
 * Get features subtitle translation
 */
export const getFeaturesSubTitle = ({
  benefitsCount,
  title,
}: {
  benefitsCount?: number;
  title: string;
}): ReactNode => {
  const subtitleParams = {
    '{count}': benefitsCount || uniqueFeaturesCount[title] || '',
  };

  switch (title) {
    case 'Premium dater':
      return t('paymentPagePackage', 'text.ALL_INCLUSIVE', subtitleParams);
    case 'Extra security':
    case 'Extra Benefits':
    case 'Chataholic':
      return t('paymentPagePackage', 'title.count_features', subtitleParams);
    case 'coins features':
      return t('paymentPagePackage', 'title.7DAY2023');
    case 'coins gold':
      return t('paymentPagePackage', 'title.7DAY2030');
    default:
      return '';
  }
};

/**
 * Get remarketing offer subtitle translation
 */
const getRemarketingOfferSubTitle = ({
  isWeekTrialPackage,
}: {
  isWeekTrialPackage: boolean;
}): ReactNode => {
  if (isWeekTrialPackage) {
    return t('paymentPagePackage', 'title.package_description_trial_week');
  }

  return t(
    'paymentPagePackage',
    'title.package_description_premium membership',
  );
};

/**
 * Get package subtitle translation
 */
const usePackageSubtitle = ({
  interval,
  isTrialMembership,
  benefitsCount,
  title,
}: {
  benefitsCount?: number;
  interval: number;
  isTrialMembership: boolean;
  title: string;
}): ReactNode => {
  const {action} = usePaymentParams();

  const isWeekTrialPackage = interval === 7 && isTrialMembership;

  switch (action) {
    case PAYMENT_ACTIONS.MEMBERSHIP:
      if (isWeekTrialPackage) {
        return t('paymentPagePackage', 'title.package_description_trial_week');
      }

      if (isTrialMembership) {
        return t(
          'paymentPagePackage',
          'title.package_description_trial membership',
        );
      }

      return t(
        'paymentPagePackage',
        'title.package_description_premium membership',
      );
    case PAYMENT_ACTIONS.FEATURES:
      return getFeaturesSubTitle({title, benefitsCount});
    case PAYMENT_ACTIONS.REMARKETING_OFFER:
      return getRemarketingOfferSubTitle({
        isWeekTrialPackage,
      });
    default:
      return '';
  }
};

export default usePackageSubtitle;
