import type {ReactNode} from 'react';

import logger from '@core/logger';
import t, {createTranslationsMap} from '@core/translations';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import PaymentBannerPlacement from '@core/payment/banners/paymentBanner/constants/PaymentBannerPlacement';

import type {BannerData} from './usePaymentBannerData';
import HolidayName from '../../../widgets/motivation/constants/HolidayName';
import UPGRADE_VIA_BY_PLACEMENT from '../constants/holidayBannerVia';
import {
  getCustomTranslationByHolidayMap,
  getDefaultTranslationMap,
} from './getHolidayTranslations';
import CUSTOM_HOLIDAY from '../constants/customHoliday';

const BUTTON_TEXT = createTranslationsMap((translate) => ({
  [PaymentBannerPlacement.NOTIF]: translate(
    'paymentBanner',
    'button.notifPaymentBannerButton',
  ),
  [PaymentBannerPlacement.USER]: translate(
    'paymentBanner',
    'button.userPaymentBannerButton',
  ),
  [PaymentBannerPlacement.USER_MEDIA]: translate(
    'paymentBanner',
    'button.userPaymentBannerButton',
  ),
  [PaymentBannerPlacement.SEARCH]: translate(
    'paymentBanner',
    'button.searchPaymentBannerButton',
  ),
  [PaymentBannerPlacement.MENU]: translate(
    'paymentBanner',
    'button.menuPaymentBannerButton',
  ),
}));

export const getHolidayTranslate = (holidayName: HolidayName): string => {
  const translate = {
    [HolidayName.CHOCOLATE_CHIP_DAY]: () =>
      t('holidayService', 'text.chocolatechipday'),
    [HolidayName.NATIONAL_DONUT_DAY]: () =>
      t('holidayService', 'text.nationaldonutday'),
    [HolidayName.ST_PATRICS_DAY]: () =>
      t('holidayService', 'text.stpatricsday'),
    [HolidayName.LAFETENATIONALE]: () =>
      t('holidayService', 'text.lafetenationale'),
    [HolidayName.IDAHOT]: () => t('holidayService', 'text.idahot'),
    [HolidayName.PIZZA_PARTY_DAY]: () =>
      t('holidayService', 'text.pizzapartyday'),
    [HolidayName.JUNE_SOLSTICE]: () => t('holidayService', 'text.junesolstice'),
    [HolidayName.NATIONAL_WINE_DAY]: () =>
      t('holidayService', 'text.nationalwineday'),
    [HolidayName.LABOR_DAY]: () => t('holidayService', 'text.laborday'),
    [HolidayName.NATIONAL_BEER_DAY]: () =>
      t('holidayService', 'text.nationalbeerday'),
    [HolidayName.CANADA_DAY]: () => t('holidayService', 'text.canadaday'),
    [HolidayName.BOURBON_DAY]: () => t('holidayService', 'text.bourbonday'),
    [HolidayName.BEST_FRIENDS_DAY]: () =>
      t('holidayService', 'text.bestfriendsday'),
    [HolidayName.INDEPENDENCE_DAY]: () =>
      t('holidayService', 'text.independenceday'),
    [HolidayName.NATIONAL_DANCE_DAY]: () =>
      t('holidayService', 'text.nationaldanceday'),
    [HolidayName.CINCO_DE_MAYO]: () => t('holidayService', 'text.cincodemayo'),
    [HolidayName.HAMBURGER_DAY]: () => t('holidayService', 'text.hamburgerday'),
    [HolidayName.NATIONAL_ABORIGINAL_DAY]: () =>
      t('holidayService', 'text.nationalaboriginalday'),
    [HolidayName.CHOCOLATE_MILKSHAKE_DAY]: () =>
      t('holidayService', 'text.chocolatemilkshakeday'),
    [HolidayName.BLACK_FRIDAY]: () => t('holidayService', 'text.blackfriday'),
    [HolidayName.BLACK_FRIDAY_ASIA]: () =>
      t('holidayService', 'text.blackfridayasia'),
    [HolidayName.VALENTINE_DAY]: () => t('holidayService', 'text.valentineday'),
    [HolidayName.HALLOWEEN]: () => t('holidayService', 'text.halloween'),
    [HolidayName.THANKSGIVING]: () => t('holidayService', 'text.thanksgiving'),
    [HolidayName.CHRISTMAS_DAY]: () => t('holidayService', 'text.christmasday'),
    [HolidayName.NEW_YEAR_DAY]: () => t('holidayService', 'text.newyearday'),
    [HolidayName.COLUMBUS_DAY]: () => t('holidayService', 'text.columbusday'),
    [HolidayName.OCTOBER_FEST]: () => t('holidayService', 'text.octoberfest'),
    [HolidayName.QUARANTINE]: () => t('holidayService', 'text.quarantine'),
    [HolidayName.GAY_PRIDE]: () => t('holidayService', 'text.gaypride'),
    [HolidayName.CHUSEOK]: () => t('holidayService', 'text.chuseok'),
  };

  const holidayTranslation = translate[holidayName];
  if (holidayTranslation) {
    return holidayTranslation();
  }

  const key = `text.${holidayName}`;
  logger.sendWarning(
    `[getHolidayTranslate] LOST TRANSLATE: (feature: holidayService, key: ${key})`,
  );
  return key;
};

/**
 * Returns the description for the holiday.
 * @param holiday - The holiday object.
 * @returns The description of the holiday.
 */
const getDescription = (holiday: {name: string}) => {
  const holidayName = holiday.name as HolidayName;
  const holidayNameTranslate = getHolidayTranslate(holidayName);

  return CUSTOM_HOLIDAY.includes(holidayName)
    ? getCustomTranslationByHolidayMap()[holidayName]
    : getDefaultTranslationMap(holidayNameTranslate);
};

/**
 * Returns the translation data for a holiday banner.
 */
export const getTranslations = (
  placement: PaymentBannerPlacement,
  holiday: {name: string},
) => {
  return {
    holidayName: getHolidayTranslate(holiday.name as HolidayName),
    description: getDescription(holiday)[placement],
    actionText: BUTTON_TEXT[placement],
  };
};

type HolidayBannerResult = {
  payUrl: string;
  holidayName: ReactNode;
  description: string;
  actionText: string;
  isAdultChangeSizeAvailable: boolean;
  withBackgroundImage: boolean;
  canUpgrade: boolean;
};

type HolidayBannerParams = {
  bannerData: BannerData;
  placement: PaymentBannerPlacement;
  userId: string;
};

/**
 * Params for displaying holiday banner.
 */
const getHolidayBannerParams = ({
  bannerData: {holidayBanner, accountStatus, isAdultChangeSizeAvailable},
  placement,
  userId,
}: HolidayBannerParams): HolidayBannerResult => {
  const payUrl = generatePayUrl({
    accountStatus,
    urlParams: {
      via: UPGRADE_VIA_BY_PLACEMENT[placement],
      viaProfileId: userId,
    },
  });
  const {holidayName, description, actionText} = getTranslations(
    placement,
    holidayBanner,
  );

  return {
    payUrl,
    holidayName,
    description,
    actionText,
    isAdultChangeSizeAvailable,
    withBackgroundImage: Boolean(holidayBanner?.layout),
    canUpgrade: !accountStatus.isPaid,
  };
};

export default getHolidayBannerParams;
