import {getClientInstance} from '@core/graphql/client';
import logger from '@core/logger';
import MessageType from '@core/messenger/common/constants/messageType';
import {
  DEBT_CREDITS_USING,
  FREE_MESSAGES_TIMEOUT,
  FREE_MESSAGE_NOT_ENOUGH_MESSAGES,
  FREE_MESSAGES_MOTIVATION,
  MASS_MAILING_PROTECTION_TIMEOUT,
} from '@core/messenger/common/constants/reasons';
import showAntiscamBlockAlertVar from '@core/graphql/vars/showAntiscamBlockAlertVar';
import getRecipientById from '@core/messenger/common/utils/getRecipientById';
import getRecipientFromCache from '@core/messenger/common/utils/getRecipientFromCache';
import addSendingMessageToCache from '@core/messenger/common/utils/addSendingMessageToCache';
import removeMessageFromCache from '@core/messenger/senderForm/utils/removeMessageFromCache';
import updateRecipientCacheAfterSendMessage from '@core/messenger/senderForm/utils/updateRecipientCacheAfterSendMessage';
import SEND_MESSAGE_MUTATION from '@core/messenger/common/graphql/mutations/sendMessage.gql';
import isValidMessage from '@core/messenger/senderForm/utils/isValidMessage';
import clearText from '@core/user/profile/current/utils/clearText';
import NotificationsService from '@core/systemNotifications/utils/NotificationsService';
import NOTIFICATION_TYPES from '@core/systemNotifications/constants/notificationTypes';
import {unmarkRecipientAsDeleted} from '@core/messenger/header/utils/deleteChat';
import PAYMENT_ACCOUNT_STATUS_QUERY from '@core/payment/common/graphql/queries/paymentAccountStatus.gql';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import {writeToSessionStorage} from '@core/utils/storage';
import {ViaEnum} from '@core/types';
import getHistory from '@core/application/utils/getHistory';

import openBuyMessagesPopupIfNeed from '@phoenix/buyMessagesPopup/utils/openBuyMessagesPopupIfNeed';
import {updateClientCreditsBalanceByAmount} from '@phoenix/credits/utils/updateCreditsBalance';
import PMA_REQUEST_PHOTO from '@phoenix/user/photo/graphql/queries/pmaRequestPhoto.gql';
import updatePhotoPrivateAttributes from '@phoenix/user/photo/utils/updatePhotoPrivateAttributes';
import updatePmaRequestPhotoData from '@phoenix/user/photo/utils/updatePmaRequestPhotoData';
import openFreeMessagesRewardsPopup from '@phoenix/freeMessagesMotivation/utils/openFreeMessagesRewardsPopup';

import isContact from '../../common/utils/isContact';
import getGlobalFreeMessagesBuyMessagesForFreeUser from '../../common/utils/getGlobalFreeMessagesBuyMessagesForFreeUser';
import openFreeMessagesTimeoutPopup from '../../freeMessagesTimeout/openFreeMessagesTimeoutPopup';
import openMassMailingTimeoutPopup from '../../massMailingTimeout/openMassMailingTimeoutPopup';
import errorProcessingAfterSendMessage from './errorProcessingAfterSendMessage';
import updatePermissionsForSendMessage from './updatePermissionsForSendMessage';
import updateQuickStickersSendAmount from './updateQuickStickersSendAmount';
import gotoBuyCreditsOnPPForSendingReason from './gotoBuyCreditsOnPPForSendingReason';
import {RECIPIENT_ID_EMPTY_DIALOG_WHERE_FREE_MESSAGES_ENDED} from '../../constants/storageKeys';
import {unsubscribeResetGlobalFreeMessagesActiveRecipientListener} from './startResetGlobalFreeMessagesActiveRecipientListener';
import updateFreeMessageMotivationBanner from './updateFreeMessageMotivationBanner';

const DEFAULT_SUBJECT_FROM_CHAT = 'hi';

const DEFAULT_METHOD = 'mail';

/**
 * Sends the message
 *
 * @param {ApolloClient} client
 * @param {string} recipientId
 * @param {MessageType} messageType
 * @param {string} message - message text
 * @param {boolean} copyPasteDetected - whether paste event was emitted during writing
 * @param {?string} placeholderId id of the message created by {@see createMessage}
 * @param {string} storyId
 * @param {string|null} coinPaymentPageReturnPath
 * @param {?string} uploadId id of the message created by {@see VideoMessageProcessingPlaceholder}
 * @param {null|function} updateRecipientAfterGet to control sending not in messenger, to update send messages cache {@see sendTemplateMessage.js}
 * @param {?number} contentLevel
 */
const sendMessage = async ({
  method = DEFAULT_METHOD,
  recipientId,
  messageType,
  message,
  copyPasteDetected,
  placeholderId,
  storyId,
  uploadId,
  contentLevel,
  messageCost,
  updateRecipientAfterGet = null,
  coinPaymentPageReturnPath = null,
}) => {
  if (!isValidMessage(message)) {
    return null;
  }

  if (!recipientId) {
    logger.sendWarning(`[sendMessage] No 'recipientId'`);
    return null;
  }

  let sendingId = placeholderId;

  if (!sendingId) {
    sendingId = addSendingMessageToCache(recipientId, {
      messageType,
      message,
      contentLevel,
    });
  }

  showAntiscamBlockAlertVar(false);

  /**
   * Calculate and update balance on client side before interaction return actual balance
   * @return revertUpdateCreditBalance - revert balance change
   */
  const {revertUpdateCreditBalance} =
    updateClientCreditsBalanceByAmount(messageCost);

  const client = getClientInstance();
  const clearMessage = clearText(message);

  return client
    .mutate({
      mutation: SEND_MESSAGE_MUTATION,
      variables: {
        messageType,
        copyPasteDetected,
        message: clearMessage,
        storyFragmentId: storyId,
        toId: recipientId,
        subject: DEFAULT_SUBJECT_FROM_CHAT,
        method,
      },
      update: async (cache, {data}) => {
        const {error, reasonExtraData} = data;
        let recipientIsAddedToContacts = false;

        /**
         * Need open buy credit popup for unsent message or not enough coins, if return error.
         */
        if (error) {
          /**
           * For a free user, verification is required if the message isn't sent from chat,
           * because in this case the recipientId may not be in the recipients cache.
           * @see TargetUserFlirtyDialog
           */
          if (error === DEBT_CREDITS_USING && !isContact(client, recipientId)) {
            recipientIsAddedToContacts = true;

            await getRecipientById(client, recipientId);
          }
          gotoBuyCreditsOnPPForSendingReason({
            reason: error,
            messageType,
            returnPath: coinPaymentPageReturnPath,
          });
        }

        if (error === FREE_MESSAGES_TIMEOUT) {
          openFreeMessagesTimeoutPopup(
            recipientId,
            reasonExtraData.freeMessagesTimeout,
          );
        }

        if (error === MASS_MAILING_PROTECTION_TIMEOUT) {
          openMassMailingTimeoutPopup(recipientId, reasonExtraData.timeout);
        }

        if (error === FREE_MESSAGE_NOT_ENOUGH_MESSAGES) {
          writeToSessionStorage(
            RECIPIENT_ID_EMPTY_DIALOG_WHERE_FREE_MESSAGES_ENDED,
            recipientId,
          );
          unsubscribeResetGlobalFreeMessagesActiveRecipientListener();
        }

        if (error === FREE_MESSAGES_MOTIVATION) {
          if (getRecipientFromCache(recipientId)?.freeMessageMotivation) {
            updateFreeMessageMotivationBanner({
              recipientId,
              isShowBanner: true,
            });
            openFreeMessagesRewardsPopup({userId: recipientId});
          }
          // TODO: change name of error after TNC-30137
          else if (await getGlobalFreeMessagesBuyMessagesForFreeUser()) {
            openBuyMessagesPopupIfNeed({
              via: ViaEnum.free5_verify,
              urlParams: {viaProfileId: recipientId},
            });
          } else {
            const {data: accountStatusData} = await client.query({
              query: PAYMENT_ACCOUNT_STATUS_QUERY,
            });

            getHistory().push(
              generatePayUrl({
                accountStatus: accountStatusData?.payment?.accountStatus,
                urlParams: {
                  via: ViaEnum.free5_verify,
                  viaProfileId: recipientId,
                },
              }),
            );
          }
        }

        if (error && error !== DEBT_CREDITS_USING) {
          errorProcessingAfterSendMessage({
            error,
            recipientId,
            sendingId,
            videoUploadId:
              messageType === MessageType.IMB_VIDEO ? uploadId : null,
          });
          return;
        }

        unmarkRecipientAsDeleted(recipientId);

        const {reason, canSendNext, sendMessage: sendMessageData} = data;
        const {
          type,
          direction,
          countFreeMessages,
          freeAssistantMessages,
          needSendFirst,
        } = sendMessageData;

        const freeMessagesTimeout =
          data.reasonExtraData?.freeMessagesTimeout || null;

        updatePermissionsForSendMessage({
          client,
          recipientId,
          needSendFirst,
          type,
          direction,
          countFreeMessages,
          freeAssistantMessages,
          canSendNext,
          reason,
          freeMessagesTimeout,
        });

        if (messageType === MessageType.PRIVATE_PHOTO_REQUEST) {
          const {data: pmaRequestData} = await client.query({
            query: PMA_REQUEST_PHOTO,
          });

          const {available} =
            pmaRequestData?.userFeatures.pmaRequestPhoto || {};

          if (available) {
            updatePmaRequestPhotoData({updateRequestCount: true});
            updatePhotoPrivateAttributes(message);
            NotificationsService.addNotification({
              type: NOTIFICATION_TYPES.PHOTO_REQUEST_IS_SUBMITTED,
            });
          } else {
            updatePhotoPrivateAttributes(message);
          }
        }

        if (!recipientIsAddedToContacts) {
          try {
            /**
             * Need to get a recipient if sendingId is not exist,
             * or recipient was deleted before send message
             * (e.x after pay coins on messenger will delete recipient data @see BuyCoinsPopup)
             * Because in that case we could not add message to cache? and need refresh recipient data
             */
            if (!sendingId || !getRecipientFromCache(recipientId)) {
              await getRecipientById(client, recipientId);

              /**
               * If user sends template from profile we need to fill recipient last message cache with
               * last message text (template text or ordinary message text) because it has not had this recipient in cache yet
               */
              updateRecipientAfterGet &&
                updateRecipientAfterGet({
                  recipientId,
                  sendingId,
                  sendMessage: sendMessageData,
                });
            } else {
              updateRecipientCacheAfterSendMessage({
                recipientId,
                sendingId,
                sendMessage: sendMessageData,
              });
            }
          } catch (e) {
            logger.sendWarning(e);
          }
        }

        /**
         * We should update amount of available stickers that can be send for
         * proper working of "Flirt" action.
         * @see FlirtAction.js
         */
        if (messageType === MessageType.STICKER) {
          updateQuickStickersSendAmount(client);
        }
      },
    })
    .catch((e) => {
      removeMessageFromCache(recipientId, sendingId);

      /**
       * Reset update balance on client side if has error
       */
      revertUpdateCreditBalance();

      if (['NO_PHOTO', 'WAIT_FOR_APPROVE'].includes(e.message)) {
        /**
         * These errors are handled by common onError handler defined in {@see createClient}.
         * Return them for ability to use additional logic
         * (e.g. blur input field when error happens in handleSendMessage in {@see SenderForm}).
         */
        return {data: {error: e.message}};
      }

      throw e;
    });
};

export default sendMessage;
