import {useMemo} from 'react';
import {useQuery} from '@apollo/client';

import {getCookie} from '@core/utils/cookie';
import {ADULT_BIG_FONT_SIZE} from '@core/payment/common/constants/cookies';

import type {AdultContentAvailableQuery} from '../graphql/queries/adultContentAvailable';
import ADULT_CONTENT_AVAILABLE_QUERY from '../graphql/queries/adultContentAvailable.gql';

type AdultContentDataResult = {
  isAdultChangeSizeAvailable: boolean;
  loading: boolean;
};

const useAdultContentData = (): AdultContentDataResult => {
  const {data, loading} = useQuery<AdultContentAvailableQuery>(
    ADULT_CONTENT_AVAILABLE_QUERY,
  );

  return useMemo<AdultContentDataResult>(() => {
    return {
      isAdultChangeSizeAvailable:
        Boolean(getCookie(ADULT_BIG_FONT_SIZE)) ||
        data?.userFeatures?.isAdultContent,
      loading,
    };
  }, [data, loading]);
};

export default useAdultContentData;
