import React from 'react';
import PropTypes from 'prop-types';

import {benefitPropType} from '@core/payment/widgets/package/constants/propTypes';
import PaymentPageAppearance from '@core/theming/constants/features/PaymentPageAppearance';

import {IconSubstrate} from '@phoenix/ui';
import {
  IconSubstrateSize,
  IconType,
  SpacingSize,
  IconSize,
} from '@phoenix/ui/constants';
import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';

import PaymentPackageBenefitsList from './PaymentPackageBenefitsList';
import css from '../styles/PaymentPackageBenefitsListWrapper.css';

/**
 * Wrapper component for render package benefits lists
 * */
const PaymentPackageBenefitsListWrapper = ({
  squareBenefitsPlusIcon,
  additionalBenefits,
  benefits,
  withBigTextSize,
  withBorderIcon,
  showBenefits,
  showAdditionalBenefits,
  spacingHorizontalAdditionalBenefit,
}) => {
  const spacingSize = withBigTextSize ? SpacingSize.SHORT : SpacingSize.NONE;
  const {data: paymentPage} = useThemeFeature('payment', 'page');
  const centeredPackages =
    paymentPage === PaymentPageAppearance.WITH_CENTERED_PACKAGES;

  const canShowAdditionalBenefits =
    showAdditionalBenefits && Boolean(additionalBenefits.length);

  if (!showBenefits && !canShowAdditionalBenefits) {
    return null;
  }

  return (
    <div className={css.list}>
      {showBenefits && (
        <PaymentPackageBenefitsList
          benefitsList={benefits}
          iconSpacing={SpacingSize.SHORT}
          iconSize={withBigTextSize ? IconSize.SIZE_24 : IconSize.SIZE_16}
          itemSpacing={spacingSize}
          small={!withBigTextSize}
        />
      )}
      {canShowAdditionalBenefits && (
        <div className={css.wrap}>
          {withBorderIcon && (
            <IconSubstrate
              icon="plus"
              size={IconSubstrateSize.SMALLEST}
              type={centeredPackages ? IconType.SUCCESS : IconType.PRIMARY}
              round={!squareBenefitsPlusIcon}
              className={squareBenefitsPlusIcon ? css.squareIcon : css.icon}
            />
          )}
          <PaymentPackageBenefitsList
            benefitsList={additionalBenefits}
            primary={!centeredPackages}
            success={centeredPackages}
            iconSpacing={SpacingSize.SHORT}
            iconSize={withBigTextSize ? IconSize.SIZE_24 : IconSize.SIZE_16}
            itemSpacing={spacingSize}
            small={!withBigTextSize}
            spacingHorizontal={spacingHorizontalAdditionalBenefit}
            adaptive={withBorderIcon}
          />
        </div>
      )}
    </div>
  );
};

PaymentPackageBenefitsListWrapper.propTypes /* remove-proptypes */ = {
  squareBenefitsPlusIcon: PropTypes.bool,
  additionalBenefits: PropTypes.arrayOf(benefitPropType).isRequired,
  benefits: PropTypes.arrayOf(benefitPropType).isRequired,
  showAdditionalBenefits: PropTypes.bool,
  withBigTextSize: PropTypes.bool,
  withBorderIcon: PropTypes.bool,
  showBenefits: PropTypes.bool,
  spacingHorizontalAdditionalBenefit: PropTypes.oneOf(
    Object.values(SpacingSize),
  ),
};

export default PaymentPackageBenefitsListWrapper;
