import React from 'react';

import t from '@core/translations/translate';

import {Icon, IconSubstrate, Row, RowShort} from '@phoenix/ui';
import {
  Align,
  IconSize,
  IconSubstrateSize,
  IconType,
} from '@phoenix/ui/constants';
import {H3, Text, TextMuted} from '@phoenix/typography';

import css from '../styles/BuyMessagesPopupMotivation.css';

const BuyMessagesPopupMotivation = () => (
  <Row align={Align.CENTER}>
    <Row align={Align.AROUND}>
      <div className={css.icons}>
        <Icon type="chat" standard size={IconSize.SIZE_96} />
        <IconSubstrate
          size={IconSubstrateSize.SMALL}
          icon="exclamation"
          type={IconType.DANGER}
          className={css.iconSmall}
        />
      </div>
    </Row>
    <RowShort>
      <H3 align={H3.ALIGN.CENTER}>
        {t('buyMessagesPopup', 'title.no_messages_left')}
      </H3>
    </RowShort>
    <TextMuted align={Text.ALIGN.CENTER}>
      {t('buyMessagesPopup', 'text.buy_one_time_pack')}
    </TextMuted>
  </Row>
);

export default BuyMessagesPopupMotivation;
