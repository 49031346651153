import React, {Fragment, useCallback} from 'react';
import PropTypes from 'prop-types';

import addActivePackageIdInUrl from '@core/payment/widgets/package/utils/addActivePackageIdInUrl';
import {packagePropType} from '@core/payment/widgets/package/constants/propTypes';
import useActivePackage from '@core/payment/widgets/package/utils/useActivePackage';
import usePaymentTabs from '@core/payment/widgets/tabs/utils/usePaymentTabs';

import useIsClickableElements from '@phoenix/payment/common/utils/useIsClickableElements';
import {Group, RowShort, Spacing} from '@phoenix/ui';
import {SpacingSize} from '@phoenix/ui/constants';
import useIsLayeredPage from '@phoenix/payment/utils/useIsLayeredPage';

import PaymentTabsSwitcher from '../../tabs/components/PaymentTabsSwitcher';
import PaymentHolidayMotivationCompact from '../../motivation/containers/PaymentHolidayMotivationCompact';
import PaymentPackageAccordion from '../components/PaymentPackageAccordion';
import PaymentPackagesAccordionPlaceholder from '../components/PaymentPackagesAccordionPlaceholder';

const PaymentPackagesAccordion = ({
  packages,
  defaultActiveTab,
  withTabs = true,
  withHolidayMotivation = false,
  withOneMethodTab = true,
  withCenteredLoPrice = false,
  withDiscountLabel = false,
  withBenefits = true,
  withBorderIcon = true,
  showBenefitsOnAllPackages = false,
  squareBenefitsPlusIcon = false,
  showAdditionalBenefitsOnAllPackages = false,
  spacingHorizontalAdditionalBenefit,
  loading,
  packagesLoading,
  onScrollToCard,
}) => {
  const {tabs, activeTab, packagesByTab, setActiveTab} = usePaymentTabs({
    packages,
    defaultActiveTab,
  });
  const {isLayered, loading: layeredLoading} = useIsLayeredPage();

  const {
    loading: isClickableElementsLoading,
    isClickablePackages,
    isClickableElements,
  } = useIsClickableElements();

  const {activePackage, setActivePackage} = useActivePackage();

  const handleSetActiveTab = useCallback(
    (tab) => {
      if (tab === activeTab && isClickableElements) {
        addActivePackageIdInUrl();
      } else {
        setActiveTab(tab);
      }
    },
    [activeTab, isClickableElements, setActiveTab],
  );

  const handleSelectPackage = useCallback(
    (packageData) => {
      const isActivePackage = activePackage.stockId === packageData.stockId;

      if (isActivePackage && isClickablePackages) {
        addActivePackageIdInUrl();
      } else {
        setActivePackage(packageData);
      }
    },
    [activePackage, isClickablePackages, setActivePackage],
  );

  if (
    !activePackage ||
    !activeTab ||
    loading ||
    packagesLoading ||
    isClickableElementsLoading ||
    layeredLoading
  ) {
    return <PaymentPackagesAccordionPlaceholder />;
  }

  return (
    <Fragment>
      {withTabs && (withOneMethodTab || tabs.length > 1) && (
        <Spacing
          size={isLayered ? SpacingSize.SHORT : SpacingSize.NONE}
          onlyBottom
        >
          <PaymentTabsSwitcher
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={handleSetActiveTab}
          />
        </Spacing>
      )}
      {withHolidayMotivation && <PaymentHolidayMotivationCompact />}
      <RowShort data-test="paymentPackagesAccordion">
        <Group space={SpacingSize.SHORT}>
          {packagesByTab.map((packageData) => {
            const checked = activePackage.stockId === packageData.stockId;

            return (
              <PaymentPackageAccordion
                key={packageData.stockId}
                package={packageData}
                onSelect={handleSelectPackage}
                onScrollToCard={onScrollToCard}
                checked={checked}
                squareBenefitsPlusIcon={squareBenefitsPlusIcon}
                showAdditionalBenefitsOnAllPackages={
                  showAdditionalBenefitsOnAllPackages
                }
                spacingHorizontalAdditionalBenefit={
                  spacingHorizontalAdditionalBenefit
                }
                withDiscountLabel={withDiscountLabel}
                withBenefits={withBenefits}
                showBenefitsOnAllPackages={showBenefitsOnAllPackages}
                withBorderIcon={withBorderIcon}
                withCenteredLoPrice={withCenteredLoPrice}
                onPageBackground={!isLayered || checked}
              />
            );
          })}
        </Group>
      </RowShort>
    </Fragment>
  );
};

PaymentPackagesAccordion.propTypes /* remove-proptypes */ = {
  packages: PropTypes.arrayOf(packagePropType).isRequired,
  defaultActiveTab: PropTypes.string.isRequired,
  withBorderIcon: PropTypes.bool,
  withTabs: PropTypes.bool,
  squareBenefitsPlusIcon: PropTypes.bool,
  withOneMethodTab: PropTypes.bool,
  showAdditionalBenefitsOnAllPackages: PropTypes.bool,
  spacingHorizontalAdditionalBenefit: PropTypes.oneOf(
    Object.values(SpacingSize),
  ),
  showBenefitsOnAllPackages: PropTypes.bool,
  withCenteredLoPrice: PropTypes.bool,
  withDiscountLabel: PropTypes.bool,
  withBenefits: PropTypes.bool,
  withHolidayMotivation: PropTypes.bool,
  loading: PropTypes.bool,
  packagesLoading: PropTypes.bool,
  onScrollToCard: PropTypes.func,
};

export default PaymentPackagesAccordion;
