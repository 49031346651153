import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {Card, Spacing, PlaceholderBar} from '@phoenix/ui';
import {SpacingSize} from '@phoenix/ui/constants';

import css from '../styles/PaymentPackageHeader.css';
import placeholderCss from '../styles/PaymentPackageHeaderPlaceholder.css';

const PaymentPackageHeaderPlaceholder = ({
  checked,
  spacingSize = SpacingSize.SHORT,
}) => {
  return (
    <Card
      className={cn(
        css.header,
        checked && css.highlighted,
        placeholderCss.header,
      )}
      shadowLevel={0}
      onPageBackground={!checked}
    >
      <Spacing size={spacingSize}>
        <div className={css.container}>
          <div className={placeholderCss.info}>
            <PlaceholderBar size={120} />
            <div className={placeholderCss.price}>
              <PlaceholderBar size={50} />
            </div>
          </div>
        </div>
      </Spacing>
    </Card>
  );
};

PaymentPackageHeaderPlaceholder.propTypes /* remove-proptypes */ = {
  checked: PropTypes.bool,
  spacingSize: PropTypes.oneOf(Object.values(SpacingSize)),
};

export default PaymentPackageHeaderPlaceholder;
