import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import BENEFIT_ICONS from '@core/payment/widgets/package/constants/benefitIcons';
import {benefitPropType} from '@core/payment/widgets/package/constants/propTypes';
import getBenefitTranslation from '@core/payment/widgets/package/utils/getBenefitTranslation';

import {Group, Icon, Spacing} from '@phoenix/ui';
import {Align, IconSize, SpacingSize} from '@phoenix/ui/constants';
import {Text} from '@phoenix/typography';

// @TODO: FE-32484 remove getBenefitTranslation when all translates from backend

import css from '../styles/PaymentPackageBenefitsList.css';

/**
 * Component for render package benefits list
 */
const PaymentPackageBenefitsList = ({
  adaptive = true,
  benefitsList = [],
  primary,
  success,
  spacingHorizontal = SpacingSize.SHORT,
  spacingVertical = SpacingSize.SHORT,
  itemSpacing = SpacingSize.NONE,
  iconSpacing = SpacingSize.SHORTEST,
  iconSize = IconSize.SIZE_16,
  largeBenefitsText,
  small = true,
}) => {
  if (!benefitsList.length) {
    return null;
  }

  return (
    <div
      className={cn(css.list, primary && css.primary, success && css.success)}
      data-test="paymentPackageBenefitsList"
    >
      <Spacing
        adaptive={adaptive}
        size={spacingHorizontal}
        withoutTop
        withoutBottom
      >
        <Spacing
          adaptive={adaptive}
          size={spacingVertical}
          withoutLeft
          withoutRight
        >
          <Group space={itemSpacing}>
            {benefitsList.map(({name, translation}) => {
              const icon = BENEFIT_ICONS[name];

              return (
                <Group
                  key={name}
                  horizontal
                  space={iconSpacing}
                  align={Align.LEFT}
                  itemWithFlexibleWidth={!icon}
                  data-test="benefitListItem"
                >
                  {icon && (
                    <Icon
                      type={icon}
                      size={iconSize}
                      standard={!primary || !success}
                      primary={primary}
                      success={success}
                    />
                  )}
                  <Text
                    align={Text.ALIGN.LEFT}
                    small={small}
                    type={
                      (primary && Text.TYPE.PRIMARY) ||
                      (success && Text.TYPE.SUCCESS) ||
                      Text.TYPE.MUTED
                    }
                    className={cn(largeBenefitsText && css.largeText)}
                  >
                    {getBenefitTranslation(name, translation)}
                  </Text>
                </Group>
              );
            })}
          </Group>
        </Spacing>
      </Spacing>
    </div>
  );
};

PaymentPackageBenefitsList.propTypes /* remove-proptypes */ = {
  adaptive: PropTypes.bool,
  benefitsList: PropTypes.arrayOf(benefitPropType).isRequired,
  primary: PropTypes.bool,
  success: PropTypes.bool,
  spacingHorizontal: PropTypes.oneOf(Object.values(SpacingSize)),
  spacingVertical: PropTypes.oneOf(Object.values(SpacingSize)),
  itemSpacing: PropTypes.oneOf(Object.values(SpacingSize)),
  iconSpacing: PropTypes.oneOf(Object.values(SpacingSize)),
  iconSize: PropTypes.oneOf(Object.values(IconSize)),
  largeBenefitsText: PropTypes.bool,
  small: PropTypes.bool,
};

export default PaymentPackageBenefitsList;
