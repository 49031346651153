import React from 'react';

import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';
import useAdultContentData from '@phoenix/user/motivation/utils/useAdultContentData';

import PaymentHolidayMotivationText from '../components/PaymentHolidayMotivationText';
import {
  getHolidayMotivationText,
  getHolidayMotivationTitle,
} from '../utils/getHolidayMotivation';
import usePaymentHolidayMotivation from '../utils/usePaymentHolidayMotivation';

const PaymentHolidayMotivationCompact = () => {
  const {loading, error, available, name, typeOccurrence} =
    usePaymentHolidayMotivation();

  const {
    isAdultChangeSizeAvailable,
    loading: isAdultChangeSizeAvailableLoading,
  } = useAdultContentData();

  if (loading || isAdultChangeSizeAvailableLoading || !available) {
    return null;
  }

  if (error) {
    return <ErrorBoundary error={error} />;
  }

  return (
    <PaymentHolidayMotivationText
      title={getHolidayMotivationTitle(name, typeOccurrence)}
      text={getHolidayMotivationText(name, typeOccurrence)}
      isBigText={isAdultChangeSizeAvailable}
    />
  );
};

export default PaymentHolidayMotivationCompact;
